@tailwind base;
@tailwind components;
@tailwind utilities;

/* .carousel-root{
  z-index: -99 !important;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
  z-index: -999 !important;
} */

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
  z-index: -99;
}
.carousel .carousel-status {
display: none;
}

.carousel .control-dots .dot {
  background: #000 !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 10px solid #000 !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 10px solid #000 !important;
}
.carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}



@media (min-width:1000px){
  .about{
    .about-container,
    .item { 
      box-sizing: border-box; 
    }
.about-container {
  display: flex;
  flex-wrap: wrap;
}


/* Begin at 3rd child; end at 1st child */
.item:nth-child(-n +3) {
  flex-basis: calc(100% / 3);  
}

/* Begin at 4th item, apply to last child */
.item:nth-child(n+4) {
  flex-basis: calc(100% / 2);
}}

}




